import { useEffect } from 'react'

export const useLoadMore = ({ loader, numberCurrent, numberLoad, setNumberCurrent }) => {
    const loadMore = () => {
        if (loader && loader.current && window.pageYOffset + window.innerHeight >= loader.current.offsetParent.offsetTop + loader.current.offsetTop) {
            setTimeout(() => {
                setNumberCurrent(numberCurrent + numberLoad)
            }, 1500);
        }
    }

    if (typeof window !== 'undefined' && window) {
        window.onscroll = (event) => {
            loadMore()
        }
    }

    useEffect(loadMore, [])
}
