import React, { useState, useRef } from 'react'
import RelatedPlatformBlock from '../Blocks/related-block/RelatedPlatformBlock'
import { usePlatforms } from '../../hooks/usePlatforms'
import Spinner from 'react-bootstrap/Spinner'
import { useLoadMore } from '../../hooks/useLoadMore'

const ShowAllPlatformBlock = ({ headline }) => {
     const data = usePlatforms()
     const { allWpCptPlatform } = data

     const platforms = allWpCptPlatform.nodes
     const numberPlatformLoad = 6
     const totalPlatforms = platforms.length
     const [numberPlatfomrsCurrent, setNumberPlatformsCurrent] = useState(numberPlatformLoad)
     const currentPlatforms = platforms.slice(0, numberPlatfomrsCurrent)
     const loader = useRef(null)

     console.log('platforms', platforms)

     useLoadMore({ loader, numberCurrent: numberPlatfomrsCurrent, numberLoad: numberPlatformLoad, setNumberCurrent: setNumberPlatformsCurrent })

     return (
          <div className="platform-wrapper">
               <RelatedPlatformBlock titleH3={headline} relatedPlatforms={currentPlatforms} />
               {numberPlatfomrsCurrent < totalPlatforms && (
                    <div className="load-more" ref={loader}>
                         Loading More
                         <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="ml-1" />
                    </div>
               )}
          </div>
     )
}

export default ShowAllPlatformBlock
