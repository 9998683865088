import React, { Fragment } from 'react'
import PostBlock from '../PostBlock'
import { logoType, getSlug, addSlashInternalLink } from '../../../helper'

const RelatedPlatformBlock = ({ titleH3, description, relatedPlatforms }) => {
    const platformSlug = getSlug(relatedPlatforms[0].uri)
    return (
        <div className="related-plaform">
            <div className="related-wrapper">
                {titleH3 && (
                    <div className="headline">
                        <h3 dangerouslySetInnerHTML={{ __html: titleH3 }}></h3>
                    </div>
                )}
                {description && <div className="sub-headline" dangerouslySetInnerHTML={{ __html: addSlashInternalLink(description) }}></div>}
                <div className="post-list">
                    <div className="row">
                        {
                            relatedPlatforms && relatedPlatforms.map((post, index) => {
                                if (post.cfPlatform.mainElements.slug) {
                                    const {
                                        platformExcerpt,
                                        platformNamePlural,
                                        iconFileSvg,
                                        iconFileImage,
                                        iconUseImageInsteadOfSvg,
                                        slug
                                    } = post.cfPlatform.mainElements
                                    const logo = logoType(
                                        iconFileSvg,
                                        iconFileImage,
                                        iconUseImageInsteadOfSvg
                                    )
                                    return (
                                        <div key={index} className="col-md-6">
                                            <PostBlock
                                                excerpt={platformExcerpt}
                                                namePlural={platformNamePlural}
                                                logo={logo}
                                                postSlug={`/${platformSlug}/${slug}`}
                                            />
                                        </div>
                                    )
                                }
                                return (
                                    <Fragment key={index}></Fragment>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RelatedPlatformBlock
