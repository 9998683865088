import { useStaticQuery, graphql } from "gatsby"

export const usePlatforms = () => {
    const data = useStaticQuery(graphql`
        query Platforms {
            allWpCptPlatform {
                nodes {
                    ...WordpressCPTPlatformRelatedFields
                }
            }
        }
    `)
    return data
}
