import React from 'react'
import Lottie from 'lottie-react'

export const IllustrationBlock = ({ illustration, illustrationMobile }) => {
     illustration = JSON.parse(illustration)
     illustrationMobile = JSON.parse(illustrationMobile)

     return (
          <>
               <Lottie animationData={illustration} className="illustration-wrapper is-desktop" />
               <Lottie animationData={illustrationMobile} className="illustration-wrapper is-mobile" />
          </>
     )
}

export default IllustrationBlock
