import React from 'react'
import { Link } from 'gatsby'

const PostBlock = ({ excerpt, namePlural, logo, postSlug }) => {
    const classContent = logo ? 'col-lg-8' : 'col-lg-12 none-logo'
    return (
        <div className="post-item">
            <div className="row">
                {
                    logo && (
                        <div className="col-lg-4">
                            <Link to={`${postSlug}/`}>
                                <div className="icon">
                                    {logo}
                                </div>
                            </Link>
                        </div>
                    )
                }
                <div className={`${classContent}`}>
                    <div className="content">
                        {namePlural && (
                            <Link to={`${postSlug}/`}>
                                <div className="title">
                                    <h4>{namePlural}</h4>
                                </div>
                            </Link>
                        )}
                        {excerpt && (
                            <div className="infor">
                                <p>{excerpt}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostBlock
